import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { constant } from '../const'; 

interface CalEvent {
  start:string;
  end:string;
  meta:string;
  title:string;
  color:{
    primary:any
    secondary:any
};
}


@Injectable({
  providedIn: 'root'
})
export class LocaleDataService {

  locale_path = constant.locale_url;

  constructor(private http:HttpClient) {}

  getRoomList():Observable<any>{
   
    return this.http.get<any>(this.locale_path+"room/getroomlist")
  }

  setChairStatus(id,borrowernumber,status):Observable<any>{
    return this.http.get<any>(this.locale_path+"room/setchairstatus/"+id+"/"+borrowernumber+"/"+status);
  }

  getEventList(branchcode):Observable<CalEvent[]>{
    return this.http.get<CalEvent[]>(this.locale_path+"event/geteventlist");
  }


  saveEvent(form):Observable<any>{

    return this.http.post<any>(this.locale_path+"event/addevent",{
      title:form.title,
      meta:form.meta,
      startTime: new Date(form.startTime).toLocaleTimeString(),
      endTime: new Date(form.endTime).toLocaleTimeString(),
      status:form.status
    })
  }

  saveRoom(data):Observable<any>{
    return this.http.post<any>(this.locale_path+"room/addroom",{
      Id:data.id,
      RoomName: data.roomName,
      BranchId:1,
      ChairCount:data.chairCount,
      Status:data.status,
      AutoCreateChair:data.autoCreateChair,
    })
  }

  deleteRoom(roomId){
    return this.http.get<any>(this.locale_path+"room/deleteroom/"+roomId)
  }
}
