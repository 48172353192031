import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS : Menu[] = [
  {
    state: 'home',
    name: 'HOME',
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'authentication',
    name: 'AUTHENTICATION',
    type: 'sub',
    icon: 'security',
    children: [
      {state: 'login', name: 'LOGIN'},
      {state: 'register', name: 'REGISTER'},
      {state: 'forgot-password', name: 'FORGOT'},
      {state: 'lockscreen', name: 'LOCKSCREEN'}
    ]
  },
  {
    state: 'error',
    name: 'ERROR',
    type: 'sub',
    icon: 'error_outline',
    children: [
      {state: '404', name: '404'},
      {state: '503', name: '503'}
    ]
  }
];

const LIBSMENUITEMS : Menu[] = [
  {
    state: 'catalogue',
    name: 'HOME',
    type: 'link',
    icon: 'explore'
  },
  // {
  //   state: 'library',
  //   name: 'KIOSKOPERATION',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'reservation', name: 'ROOMLIST'},
  //     {state: 'room', name: 'FULLNESS'},
  //     {state: 'event', name: 'CALENDAR'}
  //   ]
  // },
  {
    state: 'catalogue',
    name: 'CATALOGUE',
    type: 'sub',
    icon: 'book',
    children: [
      {state: 'biblios', name: 'BIBLIOS'},
      // {state: 'items', name: 'ITEMS'}
    ]
  },
  // {
  //   state: 'error',
  //   name: 'ERROR',
  //   type: 'sub',
  //   icon: 'error_outline',
  //   children: [
  //     {state: '404', name: '404'},
  //     {state: '503', name: '503'}
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  getLibMenus():Menu[]{
    return LIBSMENUITEMS;
  }

  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
