import { Component, ChangeDetectionStrategy, Inject, ViewChild, TemplateRef, OnInit } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';

import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours
  } from 'date-fns';
  
  import { Subject, Observable } from 'rxjs';
  
  import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarDateFormatter,
    CalendarView,
    DAYS_OF_WEEK
  } from 'angular-calendar';

  import { CustomDateFormatter } from 'app/provider/custom-date-formatter.provider';
import { LocaleDataService } from 'app/services/locale-data.service';
  

interface CalEvent {
    start:string;
    end:string;
    meta:string;
    title:string;
    color:string;
  }
  const colors: any = {
    red: {primary:'#ad2121',secondary:'#FAE3E3'},
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    }
  };
  


@Component({
    selector:"app-calendar",
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl:'calendar.component.html',
    styleUrls:['calendar.component.scss'],
    providers: [
        {
          provide: CalendarDateFormatter,
          useClass: CustomDateFormatter
        }
      ]
})

export class CalendarComponent implements OnInit{
    
    dialogRef: MatDialogRef<CalendarDialogComponent>;
    lastCloseResult: string;
    actionsAlignment: string;
    config: MatDialogConfig = {
      disableClose: false,
      width: '',
      height: '',
      position: {
        top: '',
        bottom: '',
        left: '',
        right: ''
      },
      data: {
        action: '',
        event: []
      }
    };
    numTemplateOpens = 0;
  
    view: CalendarView = CalendarView.Week

    locale: string = 'tr';

    weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

    weekendDays: number[] = [DAYS_OF_WEEK.MONDAY];
  
    viewDate: Date = new Date();
    CalendarView = CalendarView;
  
    modalData: {
      action: string,
      event: CalendarEvent
    };
  
    actions: CalendarEventAction[] = [{
      label: '<i class="editButton"></i>',
      onClick: ({event}: {event: CalendarEvent}): void => {
        this.handleEvent('Edited', event);
      }
    }, 
    // {
    //   label: '<i class="deleteButton"></i>',
    //   onClick: ({event}: {event: CalendarEvent}): void => {
    //     this.events = this.events.filter(iEvent => iEvent !== event);
    //     this.handleEvent('Deleted', event);
    //   }
    // }
];
  
    refresh: Subject<any> = new Subject();
 
    events: CalendarEvent[];
//    events : CalendarEvent[] =  [{
 
//         start: new Date('2020-02-24 11:00:00'),
//     //   start: addHours(startOfDay(new Date()), 2),
//       end: new Date('2020-02-24 14:00:00'),
//       title: 'Anne Yazar Şermin',
//       color: colors.red,
//       meta:"neler <b>neler</b> neler ordan buradan şuradan<br />deneme mesaj girişi",
//     }, 
//  ];
    events$: Observable<Array<CalendarEvent<{calEvent:CalEvent}>>>;
    activeDayIsOpen = true;
  
    constructor(public dialog: MatDialog, private local:LocaleDataService) {
        
    }

    ngOnInit(){
        this.getEventList()
    }

    getEventList(){   
        this.local.getEventList(1).subscribe(returnData=>{
            this.events = [];
            for(let i=0;i<returnData.length; i++){
                this.events.push({
                    start: new Date(returnData[i].start),
                    end: new Date(returnData[i].end),
                    title:returnData[i].title,
                    meta:returnData[i].meta,
                    color: colors.blue     
                })
            }
            // console.log("event", this.events)
        })
    }

    dayClicked({date, events}: {date: Date, events: CalendarEvent[]}): void {
  
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
          this.viewDate = date;
        }
      }
    }
  
    eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
      event.start = newStart;
      event.end = newEnd;
      this.handleEvent('Dropped or resized', event);
      this.refresh.next();
    }
  
    handleEvent(action: string, event: CalendarEvent): void {
      this.config.data = {event, action};
      this.dialogRef = this.dialog.open(CalendarDialogComponent, this.config);
  
      this.dialogRef.afterClosed().subscribe((result: string) => {
        this.lastCloseResult = result;
        this.dialogRef = null;
      });
    }
  
    // addEvent(): void {
    //   this.events.push({
    //     title: 'New event',
    //     start: startOfDay(new Date()),
    //     end: endOfDay(new Date()),
    //     color: colors.red,
    //     draggable: true,
    //     resizable: {
    //       beforeStart: true,
    //       afterEnd: true
    //     }
    //   });
    //   this.refresh.next();
    // }
  }
  
  
  @Component({
    selector: 'app-calendar-dialog',
    template: `
    <h5 class="mt-0">{{ data?.action }}</h5>
    <div>
      <pre [innerHTML]="data?.event"></pre>
    </div>
    <button mat-button type="button" (click)="dialogRef.close()">KAPAT</button>`
  })
  export class CalendarDialogComponent {
    constructor(
      public dialogRef: MatDialogRef<CalendarDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }
  }
  