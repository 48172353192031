import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {}

  getUser() {
    return this.authService.loggedIn();
  }

  // getRole() {
  //   return this.authService.getUserRole();
  // }

    canActivate():Observable<boolean>{
  
      if(this.getUser()==false){
        
        return of(true);
      }
      else{
          this.router.navigateByUrl('/authentication/login');
        return of(false);
      }
    }
}
