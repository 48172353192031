import {Pipe,PipeTransform} from '@angular/core';
import { DomSanitizer, SafeHtml,SafeUrl,SafeStyle,SafeScript,SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name:'stringConvert'
})

export class StringConvertPipe implements PipeTransform{

    constructor(protected sanitizer:DomSanitizer){}

    public transform(value:string){
        switch(value){
            case 'BOOK': return "Kitap"
            case 'ANS': return "Ansiklopedi"
            case 'CD': return "CD"
            case 'DVD': return "DVD"
            case 'JRNL': return "Dergi"
            case 'MAP': return "Harita"
            case 'NEWS': return "Gazete"
            case 'T': return "Tez"
            // case 'style': return this.sanitizer.bypassSecurityTrustStyle(value)
            // case 'script': return this.sanitizer.bypassSecurityTrustScript(value)
            // case 'url': return this.sanitizer.bypassSecurityTrustUrl(value)
            // case 'image': return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+value)
            // case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value)
            // case 'html': return this.sanitizer.bypassSecurityTrustHtml(value)
            default: throw new Error('Geçersiz alan:')
        }
    }
}
