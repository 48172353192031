import { Component, OnInit, ChangeDetectionStrategy, Inject, } from '@angular/core';
import { LocaleDataService } from 'app/services/locale-data.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
  branchId:number;
  roomName: string;
  chairCount:number;
  status:boolean;
  autoCreateChair:boolean;
}

@Component({
  selector: 'app-kiosk',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

    rooms:any;
    branchId:number;
    roomName: string;
    chairCount:number;
    status:boolean=true;
    autoCreateChair:boolean=true;

    constructor(private local: LocaleDataService,public dialog: MatDialog){}

    ngOnInit(){
      this.getRoomLists();
    }

    getRoomLists(){
      this.local.getRoomList().subscribe(returnData=>{
        this.rooms = returnData
      
      })
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(RoomAddDialog, {
        width: '400px',
        data: {
          roomName: this.roomName, 
          branchId: this.branchId,
          chairCount:this.chairCount,
          status:this.status,
          autoCreateChair:this.autoCreateChair}
      });
  
      dialogRef.afterClosed().subscribe(result => {

        this.local.saveRoom(result).subscribe(returnData=>{
          this.getRoomLists()
        })
        
    

      });
    }

    deleteRoom(roomId){
      this.local.deleteRoom(roomId).subscribe(returnData=>{
        console.log(returnData);
        this.getRoomLists()
      })
    }

    editRoom(room){
      const dialogRef = this.dialog.open(RoomAddDialog, {
        width: '600px',
        data: {
          id:room.id,
          roomName: room.roomName, 
          branchId: room.branchId,
          chairCount:room.chairCount,
          status:room.status,
          autoCreateChair:room.autoCreateChair}
      });

      dialogRef.afterClosed().subscribe(result => {

        this.local.saveRoom(result).subscribe(returnData=>{
          this.getRoomLists()
        })
        
    

      });
    }
}

@Component({
  selector: 'room-add-dialog',
  templateUrl: './room-add-dialog.html',
  styleUrls:['./room-add-dialog.scss']
})
export class RoomAddDialog {

  constructor(
    public dialogRef: MatDialogRef<RoomAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
