import {Pipe,PipeTransform} from '@angular/core';
import { DomSanitizer, SafeHtml,SafeUrl,SafeStyle,SafeScript,SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name:'safeHtml'
})

export class SafeHtmlPipe implements PipeTransform{

    constructor(protected sanitizer:DomSanitizer){}

    public transform(value:any, type:string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch(type){
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value)
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(value)
            case 'script': return this.sanitizer.bypassSecurityTrustScript(value)
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(value)
            case 'image': return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+value)
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value)
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value)
            default: throw new Error('Geçersiz alan:')
        }
    }
}
