import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as constant from '../const'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    path = constant.constant.locale_url;

    
    helper = new JwtHelperService();
    TOKEN_KEY="token"
    currentToken:any;
    constructor(private httpClient: HttpClient) { }

    httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };

    login(loginUser: any): Observable<any>{
        console.log("lo",loginUser)
        return this.httpClient.post<any>(this.path+"token/gettoken", 
      { userName: loginUser.UserName, password: loginUser.Password, loginType: loginUser.LoginType });
    }

    saveToken(token:string) {
       localStorage.setItem(this.TOKEN_KEY, token);
    }

    logOut(){
      localStorage.removeItem(this.TOKEN_KEY)
    }
  
    loggedIn(){
       this.currentToken = localStorage.getItem("token");
      //  alert(this.helper.getTokenExpirationDate(this.currentToken))
      return this.helper.isTokenExpired(this.currentToken);
    }

    getUserFullName(){
      this.currentToken = localStorage.getItem("token");
       return this.helper.decodeToken(this.currentToken).FullName;
    }

    getUserId(){
      this.currentToken = localStorage.getItem("token");
       return this.helper.decodeToken(this.currentToken).id;
    }

    getUserCode(){
      this.currentToken = localStorage.getItem("token");
       return this.helper.decodeToken(this.currentToken).code;
    }

    getUserRole(){
      this.currentToken = localStorage.getItem("token");
       return this.helper.decodeToken(this.currentToken).Role;
    }

  

    //Login(txtKullaniciAdi: HTMLInputElement, txtSifre: HTMLInputElement) {

    //    this.httpClient.post("http://localhost:63467/api/token/gettoken", { userName: txtKullaniciAdi.value, password: txtSifre.value }).subscribe((data: { token: string }) => this.tokenKey = data.token);
    //    console.log("token", this.tokenKey)
    //}


    // public isAuthenticated(): boolean {
    //   const token = localStorage.getItem('token');
    //   // Check whether the token is expired and return
    //   // true or false
    //   return !this.jwtHelper.isTokenExpired(token);
    // }

    //tokenKey: string;
    //Login(txtKullaniciAdi: HTMLInputElement, txtSifre: HTMLInputElement) {

    //    this.httpClient.post("http://localhost:63467/api/token/gettoken", { userName: txtKullaniciAdi.value, password: txtSifre.value }).subscribe((data: { token: string }) => this.tokenKey = data.token);
    //    console.log("token", this.tokenKey)
    //}

    //CheckAuthorize(inputToken: HTMLInputElement) {
    //    let _header = new HttpHeaders({
    //        'Content-Type': 'application/json',
    //        'Authorization': `Bearer ${inputToken.value}`
    //    });
    //    this.httpClient.get("http://localhost:63467/api/SampleData/WeatherForecasts", { headers: _header }).subscribe(data => { console.log("da", data) });
    //}
}
