import { NgModule } from "@angular/core"
import { SafeHtmlPipe } from "./safehtml.pipe"
import { StringConvertPipe } from "./stringconvert.pipe"


@NgModule({
    declarations:[SafeHtmlPipe,StringConvertPipe],
    imports:[],
    exports:[SafeHtmlPipe,StringConvertPipe]
})

export class PipesModule {}