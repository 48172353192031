import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { KioskLayoutComponent } from './layouts/kiosk/kiosk-layout.component';
import { LibrarianLayoutComponent } from './layouts/librarian/librarian-layout.component';
import { AuthGuard } from './session/auth.guard';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'catalogue',
  pathMatch: 'full',
}, {
  path: '',
  component: AdminLayoutComponent,
  children: [{
    path: 'home',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[AuthGuard]
  }]
},
{
  path: '',
  component: LibrarianLayoutComponent,
  children: [
    {
    path: 'library',
    loadChildren: () => import('./librarian/librarian.module').then(m => m.LibraranModule ),
    canActivate:[AuthGuard]
  }]
}, 
{
  path: '',
  component: LibrarianLayoutComponent,
  children: [
    {
    path: 'catalogue',
    loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule ),
    canActivate:[AuthGuard]
  }]
}, 
{
  path: '',
  component: KioskLayoutComponent,
  children: [
    {
    path: 'kiosk',
    loadChildren: () => import('./kiosk/kiosk.module').then(m => m.KioskModule ),
    canActivate:[AuthGuard]
  }]
},  
{
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: 'authentication',
    loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
  },{
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  }]
}, {
  path: '**',
  redirectTo: 'session/404'
}];
