import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

	constructor(public translate: TranslateService, private router:Router) {
		translate.addLangs(['en', 'fr']);
		translate.setDefaultLang('en');

		const browserLang: string = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
	}

	ngOnInit() {

	}
}
